import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Signup.css';
import { auth, googleProvider, appleProvider } from '../firebase';
import { createUserWithEmailAndPassword, signInWithPopup, updateProfile } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords don't match");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      // Optionally, update the user's display name or other profile information here
      // await updateProfile(userCredential.user, { displayName: 'User Name' });
      toast.success('Signup successful! Redirecting to login...');
      // Redirect to login after a short delay to allow the user to read the toast
      setTimeout(() => {
        navigate('/login');
      }, 2000);
    } catch (error) {
      console.error('Signup Error:', error);
      toast.error(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      toast.success('Signed up with Google!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      toast.error(error.message);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      await signInWithPopup(auth, appleProvider);
      toast.success('Signed up with Apple!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Apple Sign-In Error:', error);
      toast.error(error.message);
    }
  };

  return (
    <div className="signup-container">
      <form onSubmit={handleSubmit} className="signup-form">
        <h2>Sign Up</h2>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="your.email@example.com"
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Your password"
          />
        </div>
        <div>
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
            type="password"
            id="confirm-password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            placeholder="Confirm your password"
          />
        </div>
        <button type="submit">Sign Up</button>

        <div className="divider">
          <span>or</span>
        </div>

        <div className="social-signup">
          <button type="button" onClick={handleGoogleSignIn} className="google-btn">
            <i className="fab fa-google"></i>
            Sign up with Google
          </button>
          <button type="button" onClick={handleAppleSignIn} className="apple-btn">
            <i className="fab fa-apple"></i>
            Sign up with Apple
          </button>
        </div>
      </form>
    </div>
  );
};

export default Signup;