import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import logo from '../assets/images/logowhite192.png';
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';

const Navbar = ({ user }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleToggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully');
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
      toast.error('Failed to logout. Please try again.');
    }
  };

  return (
    <nav className="navbar-wrapper">
      <div className="navbar-content">
        <div className="logo">
          <Link to="/" onClick={closeMobileMenu}>
            <img src={logo} alt="PitchLab Logo" />
            <span className="logo-text">PitchLab</span>
          </Link>
        </div>
        <div className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <ul>
            {/* <li><a href="#services" onClick={closeMobileMenu}>Services</a></li>
            <li><a href="#about" onClick={closeMobileMenu}>About</a></li>
            <li><a href="#reviews" onClick={closeMobileMenu}>Reviews</a></li>
            <li><a href="#contact" onClick={closeMobileMenu}>Contact</a></li> */}
            {user ? (
              <>
                <li><Link to="/dashboard" onClick={closeMobileMenu}>Dashboard</Link></li>
                <li><button onClick={handleLogout}>Logout</button></li>
              </>
            ) : (
              <>
                <li><Link to="/login" onClick={closeMobileMenu}>Login</Link></li>
                <li><Link to="/signup" onClick={closeMobileMenu}>Sign Up</Link></li>
              </>
            )}
          </ul>
        </div>
        <div className="social-icons">
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
            <i className="fab fa-twitter"></i>
          </a>
        </div>
        <div 
          className="hamburger" 
          onClick={handleToggleMenu} 
          //aria-label="Toggle menu" 
          aria-expanded={isMobileMenuOpen}
        >
          <i className={`fas fa-bars ${isMobileMenuOpen ? 'open' : ''}`}></i>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;