import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import { auth, googleProvider, appleProvider } from '../firebase';
import { signInWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('User authenticated. UID:', userCredential.user.uid);
      toast.success('Login successful!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Login Error:', error);
      toast.error(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, googleProvider);
      toast.success('Signed in with Google!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Google Sign-In Error:', error);
      toast.error(error.message);
    }
  };

  const handleAppleSignIn = async () => {
    try {
      await signInWithPopup(auth, appleProvider);
      toast.success('Signed in with Apple!');
      navigate('/dashboard');
    } catch (error) {
      console.error('Apple Sign-In Error:', error);
      toast.error(error.message);
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      toast.error('Please enter your email address');
      return;
    }
    console.log('Attempting to send password reset email to:', email);
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
      toast.success('Password reset email sent. Check your inbox.');
    } catch (error) {
      console.error('Password Reset Error:', error);
      console.error('Error code:', error.code);
      console.error('Error message:', error.message);
      // ... rest of the error handling ...
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Login</h2>
        <div>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="your.email@example.com"
          />
        </div>
        <div>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            placeholder="Your password"
          />
        </div>
        <button type="submit">Login</button>

        <div className="forgot-password">
          <a href="#" onClick={handleForgotPassword}>Forgot Password?</a>
        </div>

        <div className="divider">
          <span>or</span>
        </div>

        <div className="social-login">
          <button type="button" onClick={handleGoogleSignIn} className="google-btn">
            <i className="fab fa-google"></i>
            Sign in with Google
          </button>
          <button type="button" onClick={handleAppleSignIn} className="apple-btn">
            <i className="fab fa-apple"></i>
            Sign in with Apple
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;