// src/Views/HomePage.js
import React from 'react';
import './HomePage.css';
import Header from './Header';

const HomePage = () => {
  return (
    <div className="home-page">
      <div className="content-container">
        <Header />
      </div>
    </div>
  );
};

export default HomePage;