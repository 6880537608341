import React, { useState } from 'react';
import './EmailSignup.css';
import { getFunctions, httpsCallable } from "firebase/functions";

const EmailSignup = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('Sending...');

    const functions = getFunctions();
    const subscribeEmail = httpsCallable(functions, 'subscribeEmail');

    try {
      const result = await subscribeEmail({ email });
      setStatus(result.data.message);
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      setStatus('An error occurred. Please try again.');
    }
  };

  return (
    <section className="email-signup">
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
            required
          />
        </div>
        <button type="submit">Sign Up for Updates</button>
      </form>
      {status && <p className="status-message">{status}</p>}
    </section>
  );
};

export default EmailSignup;
