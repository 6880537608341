import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './Dashboard.css';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      const user = auth.currentUser;
      if (!user) {
        console.log('No user is currently signed in.');
        return;
      }

      //console.log('Authenticated user UID:', user.uid); // Add this line

      const userDocRef = doc(db, 'users', user.uid);
      try {
        const userDoc = await getDoc(userDocRef);
        
        if (userDoc.exists()) {
          setUserInfo(userDoc.data());
        } else {
          setUserInfo(null);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        toast.error('Failed to load user data. Please try again.');
      }
    };

    fetchUserData();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      toast.success('Logged out successfully!');
      navigate('/');
    } catch (error) {
      console.error('Logout Error:', error);
      toast.error('Failed to logout. Please try again.');
    }
  };

  return (
    <div className="dashboard-container">
      <h2>Welcome to Your Dashboard!</h2>
      {userInfo ? (
        <div className="user-info">
          <p><strong>Email:</strong> {auth.currentUser?.email}</p>
          {Object.entries(userInfo).map(([key, value]) => (
            <p key={key}><strong>{key}:</strong> {value.toString()}</p>
          ))}
        </div>
      ) : (
        <p>No additional information available.</p>
      )}
      <button onClick={handleLogout} className="logout-btn">
        Logout
      </button>
    </div>
  );
};

export default Dashboard;