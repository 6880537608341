import React from 'react';
import './Header.css';
import mockupImage from '../assets/images/PitchLab-Mockup-Mainscreen-big.jpg'; // Make sure to add your image
import EmailSignup from './EmailSignup';

const Header = () => {
  return (
    <header>
      <div className="header-content">
        <div className="mockup-image">
          <img src={mockupImage} alt="PitchLab App Mockup" />
        </div>
        <div className="header-text">
          <h1>PitchLab</h1>
          <p>Advanced pitch metrics, directly on an iPhone. No sensors required.</p>
          <EmailSignup />
        </div>
      </div>
    </header>
  );
};

export default Header;
